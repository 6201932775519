



























































































































































































































































































































































































































import {
  Component, Vue, Prop, Watch,
} from 'vue-property-decorator';
import assetsModule from '@/store/modules/assetsModule';
import { getComponent, sleep } from '@/utils/helpers';
import draggable from 'vuedraggable';
import Multiselect from 'vue-multiselect';
// import workspaceModule from '@/store/modules/workspaceModule';
import scheduleModule from '@/store/modules/scheduleModule';
// import accountModule from '@/store/modules/accountModule';
// import { ALL_TASQS_LIST_ITEM } from '@/lib/constants';
// import tasqsListModule from '@/store/modules/tasqsListModule';
import metaDataModule from '@/store/modules/metaDataModule';
import DateRangePicker from 'vue2-daterange-picker';
// you need to import the CSS manually
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css';
import accountModule from '@/store/modules/accountModule';
import workflowModule from '@/store/modules/workflowModule';
import userPreferenceDB from '@/lib/userPreference';

@Component({
  components: {
    AppLayout: () => getComponent('common/AppLayout'),
    Multiselect,
    TasqsDetails: () => getComponent('tasqs/TasqsDetails'),
    Dropdown: () => getComponent('common/Dropdown'),
    TasqsMobile: () => getComponent('tasqs/TasqDetailMobile'),
    DateRangePicker,
    draggable,
  },
})
export default class TasqFilteringPopup extends Vue {
  @Prop({
    type: Object,
    required: false,
    default: {
      tasqListLevel: 'Well',
      typesOptions: [],
      selectedUsernames: [],
      selectedRoutes: [],
      selectedTeams: [],
      selectedAreas: [],
      selectedWaitingOn: [],
      cycleTimeGreaterThanFilter: null,
      cycleTimeLessThanFilter: null,
      unitsGreaterThanFilter: null,
      unitsLessThanFilter: null,
      defermentPercentGreaterThanFilter: null,
      defermentPercentLessThanFilter: null,

    },
  }) filteringDetails?: any;

    dataLoading = true;

    allTypes: any = [
      'Failure Prediction',
      'Label',
      'No Comms',
      'Off-Target RT',
      'Off-Target',
      'PM',
      'Scheduled job',
      'Setpoint',
      'state change',
      'Well down',
      'Workflow',
      'Install',
      'Off-Target Line Pressure',
      'Site Check',
      'Well Issue',
      'Label',
      'LOTO',
      'Regulatory',
      'Preventative Maintenance',
      'Gauge',
      'Plunger Change',
      'Standing Valve',
    ]

    get availableUsers() {
      return accountModule.reassignmentList.filter((u) => u && u.email).map((i) => ({
      // @ts-ignore
        text: i.name || 'Test User',
        value: i.email || '',
      })).sort((a, b) => a.text.localeCompare(b.text));
    }

    get enableRoutes() {
	  const routes = scheduleModule.enabledRoutes.map((r) => r.Route);
	  routes.sort((a, b) => {
        if (a < b) { return -1; }
        if (a > b) { return 1; }
        return 0;
      });
      return routes;
    }

    get areas() {
      return scheduleModule.areas;
    }

    get teams() {
      return workflowModule.teams;
    }

    waitingOnOptions: any = []

  dropdownJobTypeLocation = []

  dropdownUsersLocation = []

  dropdownAreasLocation = []

  dropdownRoutesLocation = []

  dropdownWaitingOnLocation = []

 dropdownTeamsLocation = []

 setTasqListLevel(level) {
   this.filteringDetails.tasqListLevel = level;
 }

 closeSeriesDialog() {
   this.dropdownJobTypeLocation = [];
   this.dropdownUsersLocation = [];
   this.dropdownJobTypeLocation = [];
   this.dropdownUsersLocation = [];
   this.dropdownAreasLocation = [];
   this.dropdownRoutesLocation = [];
   this.dropdownTeamsLocation = [];
   this.dropdownWaitingOnLocation = [];
 }

 hideDropdown() {
   this.dropdownJobTypeLocation = [];
   this.dropdownUsersLocation = [];
   this.dropdownAreasLocation = [];

   this.dropdownRoutesLocation = [];

   this.dropdownTeamsLocation = [];

   this.dropdownWaitingOnLocation = [];
 }

 async didSelectDropdownOption(e) {
		  // console.log(this.stx.drawingObjects);

 }

 didSelectJobTypeDropdown(e) {
   this.dropdownUsersLocation = [];
   this.dropdownUsersLocation = [];
   this.dropdownAreasLocation = [];

   this.dropdownRoutesLocation = [];

   this.dropdownTeamsLocation = [];
		  const rect = e.srcElement.parentElement.getBoundingClientRect();
   // @ts-ignore
		  this.dropdownJobTypeLocation = [rect.x - 10, rect.y - 500];
 }

 didSelectUsersDropdown(e) {
		  const rect = e.srcElement.parentElement.getBoundingClientRect();
   // @ts-ignore
		  this.dropdownUsersLocation = [rect.x - 10, rect.y - 300];
 }

 didSelectAreasDropdown(e) {
		  const rect = e.srcElement.parentElement.getBoundingClientRect();
   // @ts-ignore
		  this.dropdownAreasLocation = [rect.x - 10, rect.y - 100];
 }

 didSelectTeamsDropdown(e) {
		  const rect = e.srcElement.parentElement.getBoundingClientRect();
   // @ts-ignore
		  this.dropdownTeamsLocation = [rect.x - 10, rect.y - 100];
 }

 didSelectWaitingOnDropdown(e) {
   const rect = e.srcElement.parentElement.getBoundingClientRect();
   // @ts-ignore
   this.dropdownWaitingOnLocation = [rect.x - 10, rect.y - 100];
 }

 didSelectRoutesDropdown(e) {
		  const rect = e.srcElement.parentElement.getBoundingClientRect();
   // @ts-ignore
		  this.dropdownRoutesLocation = [rect.x - 10, rect.y - 100];
 }

 applyFiltering() {

 }

 resetFiltering() {

 }

 async created() {
   this.dataLoading = true;

   await sleep(1000);
   const allPredictionTypes: any = await userPreferenceDB.getItem('allPredictionTypes');
   if (allPredictionTypes && allPredictionTypes.length) {
     this.allTypes = allPredictionTypes;
   }
   const promiseList: any = [];
   promiseList.push(accountModule.getReassignmentList({ useExisting: true }));
   promiseList.push(workflowModule.listTeams({ useExisting: true }));
   promiseList.push(scheduleModule.listAreas({ useExisting: true }));
   promiseList.push(scheduleModule.getEnabledRoutes());
   promiseList.push(this.setWaitingOnOptions());
   // promiseList.push(tasqsListModule.getWaitList());

   Promise.all(promiseList).then(async () => {
     this.dataLoading = false;
     console.log('resolved');
   }, (err) => {
     console.log('Error:');
     console.log(err);
   });
 }

 async setWaitingOnOptions() {
   await metaDataModule.getDatalist();
   const requiredDataList = metaDataModule.requiredDatalist;
   const formCategoriesItem = requiredDataList.find((list) => list.title === 'Waiting On');
   // const formArtificialLiftItem = requiredDataList.find((list) => list.title === 'Artificial Lift Types');
   if (formCategoriesItem) {
     const formCategoriesPayload = await metaDataModule.getDatalist(formCategoriesItem.id);
     if (formCategoriesPayload && formCategoriesPayload.length) {
       const waitingOnOptions = formCategoriesPayload[0].ListItems.filter((field) => field.Active).map((item) => item.Name);
       // waitingOnOptions =
       this.waitingOnOptions = ['None'].concat(waitingOnOptions.sort());
     }
   }
 }
}

